import { computed, reactive, ref } from 'vue'
import { loginRuleFlag, imgCodeInfo } from './account-module'
import { sendPhoneCodeApi, queryImgCodeApi } from '@/api/login'
import { userStore } from '@/store/user'
import { checkUserState } from '../loginModule'

const useMessage = (): any => {
  const user = userStore()
  const phoneInfo = reactive({
    phone: '',
    codeNum: '',
    phoneCode: ''
  })

  const disabled = computed<boolean>(() => {
    if (loginRuleFlag.codeFlag) {
      return !(phoneInfo.phone && phoneInfo.codeNum && phoneInfo.phoneCode)
    } else {
      return !(phoneInfo.phone && phoneInfo.phoneCode)
    }
  })

  const second = ref<number>(60)
  const countdown = ():void => {
    const count = setInterval(() => {
      if (second.value === 1) {
        second.value = 60
        clearInterval(count)
      } else {
        second.value = second.value - 1
      }
    }, 1000)
  }
  const handleSendCode = () => {
    sendPhoneCodeApi(phoneInfo.phone).then(() => {
      countdown()
    })
  }

  const handleRefreshImgCode = () => {
    queryImgCodeApi().then(res => {
      imgCodeInfo.codeId = res.data.codeId
      imgCodeInfo.codeNum = res.data.codeNum
    })
  }

  const handleLogin = (phoneInfo: any) => {
    let data = {
      phone: '',
      phoneCode: ''
    }
    if (!loginRuleFlag.codeFlag) {
      data.phone = phoneInfo.phone
      data.phoneCode = phoneInfo.phoneCode
    } else {
      data = {
        ...phoneInfo,
        codeId: imgCodeInfo.codeId
      }
    }
    user.smsLogin(data, loginRuleFlag.codeFlag).then(res => {
      checkUserState(res.data.status)
    }).catch(() => {
      if (loginRuleFlag.codeFlag) {
        queryImgCodeApi().then(res => {
          imgCodeInfo.codeId = res.data.codeId
          imgCodeInfo.codeNum = res.data.codeNum
        })
      }
    })
  }

  return {
    phoneInfo,
    loginRuleFlag,
    imgCodeInfo,
    disabled,
    handleSendCode,
    second,
    handleLogin,
    handleRefreshImgCode
  }
}
export {
  useMessage
}
