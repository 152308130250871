
import { defineComponent, onMounted } from 'vue'
import { checkLoginRuleApi } from '@/api/login'
import { loginRuleFlag, useAccount, imgCodeInfo } from '../js/account-module'

export default defineComponent({
  setup () {
    const {
      userInfo,
      disabled,
      handleLogin,
      initPasswordVisible,
      queryImgCode
    } = useAccount()

    const checkLoginRule = () => {
      checkLoginRuleApi().then(res => {
        loginRuleFlag.codeFlag = res.data.codeFlag
        loginRuleFlag.registerFlag = res.data.registerFlag
        loginRuleFlag.scanFlag = res.data.scanFlag
        loginRuleFlag.msgFlag = res.data.msgFlag
        if (res.data.codeFlag) {
          queryImgCode()
        }
      })
    }

    onMounted(() => {
      checkLoginRule()
    })

    return {
      loginRuleFlag,
      imgCodeInfo,
      queryImgCode,
      handleLogin,
      userInfo,
      disabled,
      initPasswordVisible
    }
  }
})
