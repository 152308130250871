import { computed, reactive, ref } from 'vue'
import { userStore } from '@/store/user'
import { checkUserState } from '../loginModule'
import { useCancelAjax } from '@/utils/cancel-ajax'
import { queryImgCodeApi } from '@/api/login'

interface LoginRuleFlag {
  codeFlag: boolean;
  msgFlag: boolean;
  registerFlag: boolean;
  scanFlag: boolean;
}
interface ImgFace {
  codeId: string;
  codeNum: string
}

const loginRuleFlag: LoginRuleFlag = reactive({
  codeFlag: false,
  msgFlag: false,
  registerFlag: false,
  scanFlag: false
})
const imgCodeInfo: ImgFace = reactive({
  codeId: '',
  codeNum: ''
})

const useAccount = (): any => {
  const user = userStore()

  const {
    createCancelList,
    cancelFnList
  } = useCancelAjax()

  const initPasswordVisible = ref<boolean>(false)
  const userInfo = reactive({
    account: '',
    password: '',
    codeNum: ''
  })

  const disabled = computed<boolean>(() => {
    if (loginRuleFlag.codeFlag) {
      return !(userInfo.account && userInfo.password && userInfo.codeNum)
    } else {
      return !(userInfo.account && userInfo.password)
    }
  })

  const handleLogin = (userInfo: any) => {
    if (cancelFnList.value.length !== 0) {
      cancelFnList.value.forEach((item: () => void, index: number) => {
        item()
        delete cancelFnList.value[index]
      })
    }
    let params = {
      account: '',
      password: ''
    }
    if (!loginRuleFlag.codeFlag) {
      params.account = userInfo.account
      params.password = userInfo.password
    } else {
      params = {
        ...userInfo,
        codeId: imgCodeInfo.codeId
      }
    }
    user.login(params, loginRuleFlag.codeFlag, createCancelList).then(res => {
      if (res.data.passWordFlag) {
        initPasswordVisible.value = true
      } else {
        checkUserState(res.data.status)
      }
    }).catch(() => {
      queryImgCode()
    })
  }

  const queryImgCode = () => {
    queryImgCodeApi().then(res => {
      imgCodeInfo.codeId = res.data.codeId
      imgCodeInfo.codeNum = res.data.codeNum
    })
  }

  return {
    userInfo,
    disabled,
    handleLogin,
    initPasswordVisible,
    queryImgCode
  }
}

export {
  loginRuleFlag,
  useAccount,
  imgCodeInfo
}
