import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "login" }
const _hoisted_2 = { class: "login" }
const _hoisted_3 = { class: "form-box" }
const _hoisted_4 = { class: "center" }
const _hoisted_5 = { class: "c-btn-group" }
const _hoisted_6 = {
  key: 0,
  class: "l-svg-span"
}
const _hoisted_7 = { class: "login-form" }
const _hoisted_8 = {
  key: 0,
  class: "tabs"
}
const _hoisted_9 = { class: "form" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_login_register_bg = _resolveComponent("login-register-bg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_login_register_bg, null, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                (_ctx.loginRuleFlag.scanFlag)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_6, [
                      (_ctx.currentIndex !== 0)
                        ? (_openBlock(), _createBlock(_component_svg_icon, {
                            key: 0,
                            "icon-class": "ic_erweima",
                            class: "l-svg",
                            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleTags(0)))
                          }))
                        : (_openBlock(), _createBlock(_component_svg_icon, {
                            key: 1,
                            "icon-class": "ic_login_fanhui",
                            class: "l-svg",
                            onClick: _ctx.handleBack
                          }, null, 8, ["onClick"]))
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_7, [
                (_ctx.currentIndex !== 0)
                  ? (_openBlock(), _createElementBlock("ul", _hoisted_8, [
                      _createElementVNode("li", {
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleTags(1))),
                        class: _normalizeClass(_ctx.currentIndex === 1 ? 'active' : 'default')
                      }, [
                        _createElementVNode("div", null, _toDisplayString(_ctx.$t("login.accountLogin")), 1)
                      ], 2),
                      (_ctx.loginRuleFlag.msgFlag)
                        ? (_openBlock(), _createElementBlock("li", {
                            key: 0,
                            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleTags(2))),
                            class: _normalizeClass(_ctx.currentIndex === 2 ? 'active' : 'default')
                          }, [
                            _createElementVNode("div", null, _toDisplayString(_ctx.$t("login.messageRegister")), 1)
                          ], 2))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_9, [
                  (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.currentComponent)))
                ])
              ])
            ])
          ])
        ])
      ]),
      _: 1
    })
  ]))
}