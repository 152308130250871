import { ref, computed } from 'vue'
import { loginRuleFlag } from './js/account-module'
import { message, Modal } from 'ant-design-vue'
import { getToken } from '@/utils/token'
import { forceLoginApi } from '@/api/login'
import router from '@/router'
import './login.scss'

const loginModule = (): any => {
  const handleBack = () => {
    currentIndex.value = 1
  }

  const currentIndex = ref<number>(1)
  const handleTags = (index: number): void => {
    currentIndex.value = index
  }

  const currentComponent = computed<string>(() => {
    if (currentIndex.value === 1) {
      return 'AccountForm'
    } else if (currentIndex.value === 2) {
      return 'MessageForm'
    } else {
      return 'ScanCode'
    }
  })

  return {
    currentIndex,
    handleTags,
    handleBack,
    currentComponent,
    loginRuleFlag
  }
}

const checkUserState = (params:number):void => {
  if (params === 1) {
    router.push({
      path: '/ai-center/ControlConsole'
    })
  } else if (params === 2) {
    message.warning('此用户账号已被冻结，无法登录！')
  } else if (params === 3) {
    router.push({
      path: '/set-user-information'
    })
  } else if (params === 4) {
    Modal.confirm({
      title: '系统提示',
      content: '是否确认强制登录?',
      centered: true,
      onOk: () => {
        forceLoginApi({ token: getToken() as string }).then(() => {
          router.push({
            path: '/ai-center/ControlConsole'
          })
        })
      }
    })
  } else {
    message.warning('此用户账号存在异常登录情况，请联系管理员！')
  }
}

export {
  loginModule,
  checkUserState
}
