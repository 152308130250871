import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3edb6a33"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "scan-code"
}
const _hoisted_2 = { class: "code" }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.frameUrl)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("h5", null, _toDisplayString(_ctx.$t('login.scanTips')), 1),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("img", {
            src: _ctx.frameUrl,
            alt: ""
          }, null, 8, _hoisted_3)
        ])
      ]))
    : _createCommentVNode("", true)
}