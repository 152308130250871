
import { defineComponent } from 'vue'
import systemLoginBubble from '@/assets/img/login-bubble.gif'
import { loginModule } from './loginModule'
import LoginRegisterBg from '@/components/Login-register-bg/LoginRegisterBg.vue'
import AccountForm from './components/AccountForm.vue'
import MessageForm from './components/MessageForm.vue'
import ScanCode from './components/ScanCode.vue'

export default defineComponent({
  components: {
    AccountForm,
    MessageForm,
    LoginRegisterBg,
    ScanCode
  },
  setup () {
    const {
      currentIndex,
      handleTags,
      loginRuleFlag,
      handleBack,
      currentComponent
    } = loginModule()

    return {
      systemLoginBubble,
      currentIndex,
      handleTags,
      handleBack,
      currentComponent,
      loginRuleFlag
    }
  }
})
