<template>
  <a-form
    class="message-form"
    :model="phoneInfo"
    @finish="handleLogin"
  >
    <a-form-item
      name="phone"
    >
      <a-input
        size="large"
        :placeholder="$t('login.phone')"
        autocomplete="off"
        v-model:value.trim="phoneInfo.phone"
      >
        <template #prefix>
          <svg-icon
            icon-class="ic_phone"
            class="ic-svg"
          />
        </template>
      </a-input>
    </a-form-item>
    <div class="code-box" v-if="loginRuleFlag.codeFlag">
      <a-form-item
        class="item-code"
        name="codeNum"
      >
        <a-input
          size="large"
          autocomplete="off"
          :placeholder="$t('login.codeNum')"
          v-model:value.trim="phoneInfo.codeNum"
        >
          <template #prefix>
            <svg-icon
              icon-class="ic_prove"
              class="ic-svg"
            />
          </template>
        </a-input>
      </a-form-item>
      <div class="code">
        <img
          :src="'data:image/png;base64,' + imgCodeInfo.codeNum"
          v-if="imgCodeInfo.codeNum"
          @click="handleRefreshImgCode"
        />
      </div>
    </div>
    <div class="code-box">
      <a-form-item
        class="item-code"
        name="phoneCode"
      >
        <a-input
          size="large"
          autocomplete="off"
          :placeholder="$t('login.phoneVerifyCode')"
          v-model:value.trim="phoneInfo.phoneCode"
        >
          <template #prefix>
            <svg-icon
              icon-class="ic_phone"
              class="ic-svg"
            />
          </template>
        </a-input>
      </a-form-item>
      <div class="code">
        <st-button
          type="primary"
          class="phoneButton"
          :disabled="phoneInfo.phone === '' || second !== 60"
          @click="handleSendCode"
        >
          {{ second === 60 ? $t("login.sendCode") : `${$t("login.isSent")}（${second}s）`}}
        </st-button>
      </div>
    </div>
    <a-form-item>
      <st-button
        type="primary"
        block
        class="loginButton"
        :disabled="disabled"
        html-type="submit"
      >
        {{ $t("login.loginWord") }}
      </st-button>
    </a-form-item>
    <div class="help">
      <div style="float: right">
        <router-link to="/register" v-if="loginRuleFlag.registerFlag">
          {{$t("login.register")}}
        </router-link>
        <router-link to="/find-password">{{ $t("login.findPassword") }}</router-link>
      </div>
    </div>
  </a-form>
</template>
<script>
import { defineComponent } from 'vue'
import { useMessage } from '../js/message-module'
export default defineComponent({
  setup () {
    const {
      disabled,
      imgCodeInfo,
      loginRuleFlag,
      phoneInfo,
      handleSendCode,
      second,
      handleLogin,
      handleRefreshImgCode
    } = useMessage()

    return {
      imgCodeInfo,
      loginRuleFlag,
      disabled,
      phoneInfo,
      handleSendCode,
      second,
      handleLogin,
      handleRefreshImgCode
    }
  }
})
</script>
