
import { defineComponent, ref, onMounted, onBeforeUnmount } from 'vue'
import { qrcodeApi, qrcodeQueryApi } from '@/api/login'
import { checkUserState } from '../loginModule'
import { setToken } from '@/utils/token'

export default defineComponent({
  setup () {
    const frameUrl = ref<string>('')
    const timer = ref()

    const getQrCode = () => {
      qrcodeApi().then(res => {
        frameUrl.value = 'https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=' + res.data.base64Img
        handleWxShare(res.data.sceneCode)
      })
    }

    const handleWxShare = (data: string) => {
      timer.value = setInterval(() => {
        scanCode(data)
      }, 1000)
    }

    const scanCode = async (data: string) => {
      const res = await qrcodeQueryApi(data)
      if (res.data !== '') {
        setToken(res.data.token)
        checkUserState(res.data.status)
      }
    }

    onMounted(() => {
      getQrCode()
    })

    onBeforeUnmount(() => {
      clearInterval(timer.value)
    })

    return {
      frameUrl
    }
  }
})
